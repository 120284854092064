import Vue from 'vue';
import VueI18n from 'vue-i18n';
import tr from '@/locales/tr';

Vue.use(VueI18n);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'tr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'tr',
  messages: { tr },
  numberFormats: {
    'tr-TR': {
      currency: {
        style: 'currency',
        currency: 'TRY',
        currencyDisplay: 'symbol',
      },
    },
  },
});
