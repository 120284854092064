import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VuetifyConfirm from 'vuetify-confirm';
import i18n from '@/plugins/i18n';

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  lang: {
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi',
    values: {},
  },
});

Vue.use(VuetifyConfirm, { vuetify, buttonTrueText: 'Evet', buttonFalseText: 'Hayır', title: 'Uyarı', persistent: true });
