export function timeOfDay() {
  const today = new Date();
  const curHr = today.getHours();
  if (curHr < 6) {
    return 0;
  } else if (curHr < 12) {
    return 1;
  } else if (curHr < 18) {
    return 2;
  } else {
    return 3;
  }
}

export function hashToObj(hash) {
  if (hash.length > 0 && hash.includes('token')) {
    return Object.fromEntries(
      hash
        .substring(1)
        .split(';')
        .map((i) => i.split(':')),
    );
  }
  return {};
}

export function catchObjectToForm(record) {
  const form = new FormData();
  for (const [k, v] of Object.entries(record)) {
    switch (k) {
      case 'costs':
        for (const cost of v) {
          form.append(k, JSON.stringify(cost));
        }
        break;
      case 'files':
      case 'pictures':
        if (v) {
          for (const file of v) {
            form.append(`${k}`, file);
          }
        }
        break;
      case 'existFiles':
      case 'existPictures':
        for (const file of v) {
          form.append(k, JSON.stringify(file));
        }
        break;
      case 'owner':
        form.append(k, typeof v === 'string' ? v : v.short);
        break;
      default:
        if (v) {
          form.append(k, v);
        }
        break;
    }
  }

  return form;
}

export function convertDatesInObj(object, paths) {
  const obj = { ...object };
  for (const path of paths) {
    obj[path] = new Date(obj[path]);
  }
  return obj;
}
