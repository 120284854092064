import { tr } from 'vuetify/lib/locale';

const lang = {
  $vuetify: { ...tr },
  automobile: 'Binek',
  suv: 'Suv',
  commercial: 'Ticari',
  'heavy-commercial': 'Ağır Ticari',
  motorcycles: 'Motorsiklet',
  minivans: 'Minibüs',
  bus: 'Otobüs',
  truck: 'Kamyon',
  damaged: 'Hasarlı',

  storage: 'Depolama',
  washing: 'Yıkama',
  'traffic-consultancy': 'Trafik Müşavirliği',
  logistics: 'Lojistik',
  'yeddi̇-emi̇n': 'Yedd-i Emi̇n Otopark',
  'vehicle-sales': 'Araç Satış',
  expertise: 'Ekspertiz',
  other: 'Diğer',

  showcase: 'Birincil',
  online: 'Vitrin',
  stock: 'Stok Giriş',
  stockOut: 'Stok Çıkış',
  auction: 'İhaleye Özel',
  levy: 'İcra İhalesine Özel Resim',
  // expertise: "Ekspertiz",

  front: 'Ön',
  'right-side': 'Sağ Yan',
  back: 'Arka',
  'left-side': 'Sol Yan',
  ceiling: 'Tavan',
  dashboard: 'Gösterge Paneli',
  'key-and-license': 'Anahtar ve Ruhsat',
  'delivery-form': 'Teslim Formu',
  'front-console': 'Ön Konsol',
  'back-seat': 'Arka Koltuk',
  'luggage-and-tools': 'Bagaj ve Avandanlık',
  'spare-wheel': 'Stepne',
  'tires-and-rims-left-front': 'Lastik ve Jantlar (Sol ön)',
  'tires-and-rims-left-rear': 'Lastik ve Jantlar (Sol arka)',
  'tires-and-rims-right-front': 'Lastik ve Jantlar (Sağ ön)',
  'tires-and-rims-right-rear': 'Lastik ve Jantlar (Sağ arka)',
  'damage-0': 'Hasar 1',
  'damage-1': 'Hasar 2',
  'damage-2': 'Hasar 3',
  'damage-3': 'Hasar 4',
  'damage-4': 'Hasar 5',
  'damage-5': 'Hasar 6',
  'damage-6': 'Hasar 7',
  'damage-7': 'Hasar 8',
  'damage-8': 'Hasar 9',
  'damage-9': 'Hasar 10',
};

export default lang;
