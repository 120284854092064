import Vue from 'vue';
import VueRouter from 'vue-router';
import Levies from '../views/Levies.vue';
import Catch from '../views/Catch.vue';
import VehiclePhotos from '../views/VehiclePhotos.vue';
import { hashToObj } from '@/utils/miscellaneous';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter: ({ hash }, from, next) => {
      const params = hashToObj(hash);
      if (params.to) {
        next({ path: params.to[0] === '/' ? `${params.to}` : `/${params.to}` });
      } else {
        next({ path: '/levies' });
      }
    },
  },
  {
    path: '/levies',
    name: 'Levies',
    component: Levies,
  },
  {
    path: '/catch',
    name: 'Catch',
    component: Catch,
  },
  {
    path: '/vehicle/:id/photos',
    name: 'VehiclePhotos',
    component: VehiclePhotos,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
