import headerCheck from './http-status-check';
import { omit } from 'lodash-es';

export default async function request({ url, method = 'GET', query = {}, body, form, token, json = true }) {
  if (!navigator.onLine) {
    return Promise.reject(new Error('Device is offline'));
  }
  if (!url) {
    return Promise.reject(new Error('Url is undefined'));
  }

  /*`${url.replace(/[^:/.A-Za-z0-9-+%_]/gi, '+')}`*/
  const Uri = new URL(url, !url.includes(':') ? window.location.origin : undefined);
  for (const [k, v] of Object.entries(query)) {
    Uri.searchParams.append(k, v.toString());
  }
  Uri.pathname = Uri.pathname.replace(/[^:/.A-Za-z0-9-+%_]/gi, '+');

  const authorization = token ? { Authorization: `Bearer ${token}` } : {};
  let options = {
    headers: {
      'Content-Type': 'application/json',
      ...authorization,
    },
  };
  switch (method) {
    case 'PUT':
    case 'POST':
      options = {
        ...(form ? omit(options, ['headers.Content-Type']) : options),
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrer: 'no-referrer',
        body: form || JSON.stringify(body),
      };
      break;
    case 'DELETE':
      break;
    case 'GET':
    default:
      method = 'GET';
      break;
  }
  options.method = method;

  return fetch(Uri, options)
    .then(headerCheck)
    .then((res) => (json ? res.json() : res.text()));
}
