import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
dayjs.locale('tr');

Vue.filter('phone', function (value) {
  return value.replace(/[^+0-9]/g, '');
});
Vue.filter('formatNumber', function (value) {
  return value.toLocaleString('tr-TR', { style: 'decimal' });
});
Vue.filter('formatCurrency', function (price, currency = 'TRY') {
  return price.toLocaleString('tr-TR', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
});
Vue.filter('two_digits', (value) => {
  if (value < 0) {
    return '00';
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
});
Vue.filter('dayjs', function (value, ...params) {
  const [method, ...rest] = params;
  if (method && method in dayjs.prototype) {
    return dayjs(value)[method].apply(null, rest);
  } else {
    return dayjs(value).format(method || 'dddd HH:mm, DD MMMM YYYY');
  }
});
