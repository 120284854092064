function mediaConvert(media1, media2) {
  return {
    pictures: media1?.map((i) => ({
      ...i,
      show: Object.entries(i.show)
        .filter((x) => x[1])
        .map((v) => v[0]),
    })),
    videos: media2?.map((i) => ({
      ...i,
      show: Object.entries(i.show)
        .filter((x) => x[1])
        .map((v) => v[0]),
    })),
  };
}

export default {
  namespaced: true,
  state: {},
  actions: {},
  getters: {
    allMedia: (state, getters, rootState) => mediaConvert(rootState.vehicles.vehicle?.pictures, rootState.vehicles.vehicle?.videos),
    allDamageMedia: (state, getters, rootState) => mediaConvert(rootState.vehicles.vehicle?.damagePictures, rootState.vehicles.vehicle?.damageVideos),
  },
  mutations: {},
};
