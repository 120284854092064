<template>
  <v-container fluid>
    <div v-if="loading" class="mt-5">
      <v-skeleton-loader type="card-avatar, article, actions" :loading="loading"></v-skeleton-loader>
    </div>
    <v-row dense>
      <v-col>
        <v-row dense>
          <v-col v-for="picture in allMedia.pictures" :key="picture.sid">
            <v-card class="mx-auto" max-width="260">
              <!--<v-toolbar dense>
                <v-toolbar-title class="text-body-1 font-weight-bold">{{ picture.created | dayjs('DD.MM.YYYY HH:mm') }}</v-toolbar-title>
              </v-toolbar>-->
              <v-img :src="picture.base" height="150"></v-img>
              <v-card-title class="text-body-1 font-weight-bold pt-2 pb-0">{{ picture.created | dayjs('ddd HH:mm, DD MMMM YYYY') }}</v-card-title>
              <!--<v-card-subtitle class="font-weight-bold pt-2 pb-0">{{ picture.created | dayjs('ddd HH:mm, DD MMMM YYYY') }}</v-card-subtitle>-->
              <v-card-text class="pb-0">
                <v-chip-group column>
                  <v-chip v-for="tag in picture.show" :key="tag" color="orange" class="white--text">
                    {{ $t(tag) }}
                  </v-chip>
                  <v-chip v-for="tag in picture.tags" :key="tag" color="green" class="white--text">
                    {{ $t(tag) }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="orange" text @click="edit = true">Düzenle</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-scale-transition origin="top right 0">
        <v-col cols="4" v-show="edit">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="edit = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
            </v-toolbar>
            <v-img height="250" src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
            <v-card-text> Asd </v-card-text>
          </v-card>
        </v-col>
      </v-scale-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'VehiclePhotos',
  data: () => ({
    loading: true,
    edit: false,
    media: [],
  }),
  created() {
    this.CLEAR();
    Promise.allSettled([this.fetchVehicle(this.$route.params.id)]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters('vehicles/photos', ['allMedia', 'allDamageMedia']),
  },
  methods: {
    ...mapActions('vehicles', ['fetchVehicle']),
    ...mapMutations('vehicles', ['CLEAR']),
  },
};
</script>
