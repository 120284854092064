export default async function headerCheck(res) {
  if (!res) {
    return Promise.reject('Response is undefined!');
  }
  if (res.status >= 200 && res.status < 300) {
    return res;
  }
  let err = null;
  try {
    const json = await res.json();
    if ('error' in json) {
      err = json.error;
    }
    if (!err && 'message' in json) {
      err = json.error;
    }
    //eslint-disable-next-line no-empty
  } catch (e) {}

  if (!err) {
    try {
      err = await res.text();
    } catch (e) {
      err = res.statusText;
    }
  }

  return Promise.reject(err);
}
