import Vue from 'vue';
import Vuex from 'vuex';
import core from './modules/core';
import levies from './modules/levies';
import catches from './modules/catches';
import vehicles from './modules/vehicles';

Vue.use(Vuex);

export default new Vuex.Store({
  ...core,
  modules: { levies, catches, vehicles },
});
