import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';
import { vuetify } from './plugins/vuetify';
import './filters/custom';
import { hashToObj } from '@/utils/miscellaneous';
import i18n from './plugins/i18n';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

let { token } = hashToObj(window.location.hash);

if (!token && sessionStorage.getItem('token')) {
  token = sessionStorage.getItem('token');
} else if (!token && localStorage.getItem('token')) {
  token = localStorage.getItem('token');
} else if (!token && localStorage.getItem('ngStorage-token')) {
  token = JSON.parse(localStorage.getItem('ngStorage-token'));
}

store.dispatch('initial', token);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
