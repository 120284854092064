import Vue from 'vue';
import { pick } from 'lodash-es';
import request from '@/utils/api';

export default {
  namespaced: true,
  state: {
    levies: {},
  },
  actions: {
    async fetchLevies({ commit, rootState }) {
      const { api, token } = rootState;
      const levies = await request({ url: `${api}/levies/future`, token });
      commit('LEVIES_LIST', { levies, type: 'future' });
      return true;
    },
    async fetchOldLevies({ commit, rootState }) {
      const { api, token } = rootState;
      const levyHistory = await request({ url: `${api}/levies/history`, token });
      commit('LEVIES_LIST', { levies: levyHistory, type: 'history' });
      return true;
    },
    async fetchLevy({ commit, rootState }, id) {
      const { api, token } = rootState;
      const levy = await request({ url: `${api}/levies/${id}`, token });
      commit('SET_LEVY', levy);
    },
    async cancelLevy({ commit, rootState }, id) {
      const { api, token } = rootState;
      await request({ url: `${api}/levies/${id}`, method: 'DELETE', token });
      commit('UNSET_LEVY', id);
    },
  },
  getters: {
    leviesByDate: ({ levies }) =>
      Object.values(levies).sort((a, b) => {
        return b.startDate - a.startDate;
      }),
  },
  mutations: {
    CLEAR: (state) => {
      Vue.set(state, 'levies', {});
    },
    LEVIES_LIST: (state, { levies, type }) => {
      levies.forEach(({ _id, style, listingDate, startDate, endDate, code, deposits, listing }) => {
        Vue.set(state.levies, _id, {
          _id,
          type,
          style,
          listingDate: new Date(listingDate),
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          code,
          counts: { deposits, listing },
        });
      });
    },
    SET_LEVY: (state, { isPaused, style, targetCompany, _id, listingDate, startDate, listing, endDate, code, deposits }) => {
      const curr = state.levies[_id] || {};
      Vue.set(state.levies, _id, {
        ...curr,
        isPaused,
        style,
        targetCompany,
        _id,
        listingDate: new Date(listingDate),
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        listing: listing.map((list) => {
          return {
            ...pick(list, ['_id', 'bids', 'increment', 'order', 'plate', 'priceTag']),
            startDate: new Date(list.startDate),
            endDate: new Date(list.endDate),
            vehicle: list.id,
          };
        }),
        code,
        deposits,
      });
    },
    UNSET_LEVY: (state, id) => {
      Vue.delete(state.levies, id);
    },
  },
};
