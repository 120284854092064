import request from '@/utils/api';
import photos from '@/store/modules/vehicles/photos';

function getInitialState() {
  return {
    vehicle: {},
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    async fetchVehicle({ commit, rootState }, id) {
      const { api, token } = rootState;
      const vehicles = await request({ url: `${api}/vehicles/${id}`, token });
      commit('SET_VEHICLE', vehicles);
    },
  },
  getters: {},
  mutations: {
    CLEAR: (state) => {
      state.vehicle = getInitialState();
    },
    SET_VEHICLE: (state, vehicle) => {
      state.vehicle = vehicle;
    },
  },
  modules: { photos },
};
