import request from '@/utils/api';

export default {
  state: {
    api: process.env.VUE_APP_STORE_API,
    auth: process.env.VUE_APP_STORE_AUTH,
    token: null,
    user: null,
    brands: [],
    costs: [],
    companies: [],
    vehicleType: ['automobile', 'suv', 'commercial', 'heavy-commercial', 'motorcycles', 'minivans', 'bus', 'truck', 'damaged'],
  },
  actions: {
    initial({ /*state, commit,*/ dispatch }, token) {
      dispatch('setToken', token);
      dispatch('getUser');
    },
    setToken({ commit }, token) {
      localStorage.setItem('token', token);
      commit('SET_TOKEN', token);
    },
    getUser({ commit, state }) {
      return request({ url: `${state.auth}`, token: state.token }).then((user) => {
        commit('SET_USER', user);
        return Promise.resolve();
      });
    },
    getDefinitions({ commit, state }) {
      return request({ url: `${state.api}/car-definitions`, token: state.token }).then((definitions) => {
        commit('SET_CAR_DEFINITIONS', definitions);
        return Promise.resolve();
      });
    },
    getCostDefinitions({ commit, state }) {
      return request({ url: `${state.api}/cost-definitions/grouped`, token: state.token }).then((definitions) => {
        commit('SET_COST_DEFINITIONS', definitions);
        return Promise.resolve();
      });
    },
    getCompanies({ commit, state }) {
      return request({ url: `${state.api}/companies`, query: { editorial: false }, token: state.token }).then((companies) => {
        commit('SET_COMPANIES', companies);
        return Promise.resolve();
      });
    },
  },
  getters: {
    isAllPowerful(state) {
      return state.user?.roles.includes('ADMIN') || state.user?.roles.includes('DEVELOPER');
    },
    brandById: ({ brands }) => (id) => brands.find((brand) => brand._id === id),
    costFlat: ({ costs }) => Object.values(costs).flat(),
    costByCode: (state, { costFlat }) => (code) => costFlat.find((cost) => cost.code === code),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_CAR_DEFINITIONS: (state, def) => {
      state.brands = def;
    },
    SET_COST_DEFINITIONS: (state, def) => {
      state.costs = def;
    },
    SET_COMPANIES: (state, comp) => {
      state.companies = comp;
    },
  },
};
