import request from '@/utils/api';
import { catchObjectToForm } from '@/utils/miscellaneous';

export default {
  namespaced: true,
  state: {
    catches: [],
  },
  actions: {
    async fetchCatches({ commit, rootState }) {
      const { api, token } = rootState;
      const catches = await request({ url: `${api}/catches`, token });
      commit('CATCHES_LIST', catches);
      return true;
    },
    async fetchCatch({ commit, rootState }, id) {
      const { api, token } = rootState;
      const catch1 = await request({ url: `${api}/catches/${id}`, token });
      commit('SET_CATCH', catch1);
    },
    async setCatch({ commit, rootState }, record) {
      const { api, token } = rootState;
      const form = catchObjectToForm(record);
      const catch1 = await request({ url: `${api}/catches`, method: 'POST', form, token });
      commit('APPEND_CATCH', catch1);
    },
    async updateCatch({ rootState, dispatch }, record) {
      const { api, token } = rootState;
      const form = catchObjectToForm(record);
      const response = await request({ url: `${api}/catches/${record._id}`, method: 'PUT', form, token });
      if (response.ok === 1) {
        await dispatch('fetchCatch', record._id);
      }
    },
    async removeCatch({ commit, rootState }, id) {
      const { api, token } = rootState;
      const response = await request({ url: `${api}/catches/${id}`, method: 'DELETE', token });
      if (response.ok === 1) {
        commit('UNSET_CATCH', id);
      }
    },
  },
  getters: {
    catches: ({ catches }, getters, rootState, { brandById }) => {
      return catches.map((catch1) => ({ ...catch1, brand: brandById(catch1.brand)?.brand }));
    },
    catchById: ({ catches }) => (id) => catches.find((item) => item._id === id),
  },
  mutations: {
    CLEAR: (state) => {
      state.catches = [];
    },
    CATCHES_LIST: (state, catches) => {
      state.catches = catches;
    },
    APPEND_CATCH: (state, catch1) => {
      state.catches.push(catch1);
    },
    SET_CATCH: (state, catch1) => {
      state.catches = state.catches.map((item) => (item._id === catch1._id ? catch1 : item));
    },
    UNSET_CATCH: (state, catchId) => {
      state.catches = state.catches.filter((item) => item._id !== catchId);
    },
  },
};
