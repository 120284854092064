<template>
  <v-container fluid>
    <v-breadcrumbs>
      <v-breadcrumbs-item to="/levies">Tüm İhaleler</v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-expansion-panels focusable inset>
      <v-expansion-panel v-for="levy in leviesByDate" :key="levy._id">
        <v-expansion-panel-header>
          <span>
            <v-icon medium v-if="levy.type === 'future'" color="green darken-2">mdi-clipboard-clock-outline</v-icon>
            <v-icon medium v-else color="red darken-2">mdi-history</v-icon>
            {{ levy.startDate | dayjs }} tarihli İhale
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col cols="4">
              <div class="d-flex flex-column justify-space-between">
                <div class="text-subtitle-2">Teminat Ödemeleri</div>
                <div class="text-body-2">{{ levy.counts.deposits }}</div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex flex-column justify-space-between">
                <div class="text-subtitle-2">İhaleye Katılan Araç</div>
                <div class="text-body-2">{{ levy.counts.listing }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div class="d-flex flex-column justify-space-between">
                <div class="text-subtitle-2">Listeleme Başlangıç</div>
                <div class="text-body-2">{{ levy.listingDate | dayjs }}</div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex flex-column justify-space-between">
                <div class="text-subtitle-2">İhale Başlangıç</div>
                <div class="text-body-2">{{ levy.startDate | dayjs }}</div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex flex-column justify-space-between">
                <div class="text-subtitle-2">İhale Bitiş</div>
                <div class="text-body-2">{{ levy.endDate | dayjs }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-1" align="center" justify="space-between">
            <v-btn v-if="levy.type === 'history'" @click="report(levy._id)"> Excel İndir</v-btn>
            <v-btn color="warning" v-if="levy.type === 'future'" @click="cancel(levy._id)"> İptal Et</v-btn>
            <v-btn color="primary" @click="detail(levy._id)"> Detay</v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="loading" class="mt-5">
      <v-skeleton-loader type="text@3" :loading="loading"></v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Levies',
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.CLEAR();
    Promise.allSettled([this.fetchLevies(), this.fetchOldLevies()]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters('levies', ['leviesByDate']),
  },
  methods: {
    ...mapActions('levies', ['fetchLevies', 'fetchOldLevies', 'cancelLevy', 'fetchLevy']),
    ...mapMutations('levies', ['CLEAR']),
    async cancel(id) {
      const res = await this.$confirm('İptal etmek istediğinizden emin misiniz?');
      if (res) {
        await this.cancelLevy(id);
      }
    },
    detail(id) {
      // console.log('detail', id);
      this.fetchLevy(id);
    },
    report(id) {
      console.log('report', id);
    },
  },
};
</script>
