<template>
  <v-container fluid>
    <v-data-table :headers="table.headers" :items="catches" :items-per-page="table.itemsPerPage" :loading="loading" multi-sort class="elevation-1 mt-5">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Yakalama Araçları</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogAdd" fullscreen transition="dialog-bottom-transition" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Yeni Kayıt</v-btn>
            </template>
            <v-card>
              <v-toolbar dark color="primary" max-height="70">
                <v-btn icon dark @click="clean" :loading="loading">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Yeni Kayıt</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click.stop="addRecord" :loading="loading" :disabled="!formValid.vehicle || !formValid.explanation || !formValid.snitch">Kaydet</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-container fluid>
                  <v-stepper v-model="step">
                    <v-stepper-header>
                      <v-stepper-step :complete="step > 1" step="1">Araç Bilgisi</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="step > 2" step="2">Yakalama Bilgisi</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="step > 3" step="3">Açıklama</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="step > 4" step="4">Dosyalar</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="5">Maliyetler</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-form v-model="formValid.vehicle" ref="vehicle">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-text-field v-model="payload.plate" :rules="rules.plate" label="Plaka" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-text-field v-model="payload.chassis" label="Şase" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-select v-model="payload.brand" :items="brands" :rules="rules.exist" label="Marka" outlined item-value="_id" item-text="brand" required />
                              </v-col>
                              <v-col>
                                <v-select v-model="payload.bodyType" :items="vehicleTypeI18n" :rules="rules.exist" label="Araç tipi" outlined required />
                              </v-col>
                              <v-col>
                                <v-text-field v-model="payload.series" :rules="rules.exist" label="Model" outlined clearable required />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block color="primary" @click="step = 2" :disabled="!formValid.vehicle" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <v-form v-model="formValid.snitch" ref="snitch">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-text-field v-model="payload.snitch" :rules="rules.exist" label="Bildiren" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-combobox v-model="payload.owner" :rules="rules.exist" :items="companies" item-text="short" item-value="short" label="Ait Olduğu Kurum" outlined clearable required />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 1" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 3" :disabled="!formValid.snitch" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <v-form v-model="formValid.explanation" ref="explanation">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-textarea v-model="payload.explanation" :rules="rules.exist" label="Giriş Açıklaması" outlined clearable required rows="3" counter auto-grow />
                              </v-col>
                              <v-col>
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="payload.date" label="Giriş Tarihi" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="payload.date" @input="menu = false" :max="maxDate"></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 2" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 4" :disabled="!formValid.explanation" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="4">
                        <v-form v-model="formValid.files" ref="files">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-file-input
                                  v-model="payload.files"
                                  show-size
                                  counter
                                  chips
                                  multiple
                                  label="Belgeler"
                                  accept="image/*,application/pdf,text/plain,.doc,.docx,.odt,.xls,.xlsx"
                                  prepend-icon="mdi-file"
                                ></v-file-input>
                              </v-col>
                              <v-col>
                                <v-file-input v-model="payload.pictures" show-size counter chips multiple label="Fotoğraflar" accept="image/*" prepend-icon="mdi-camera"></v-file-input>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 3" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 5" :disabled="!formValid.files" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="5">
                        <v-form v-model="formValid.costs" ref="costs">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-card elevation="2">
                                  <v-card-title>Maliyet Ekle</v-card-title>
                                  <v-card-text>
                                    <v-form v-model="formValid.costAdd">
                                      <v-select v-model="costAdd.cost" :items="costDefinitions" :rules="rules.exist" label="Maliyet" outlined clearable required dense />
                                      <v-switch v-model="costAdd.includeBill" label="Faturada Göster" outlined dense></v-switch>
                                      <v-textarea v-model="costAdd.explanation" label="Açıklama" :rules="rules.exist" outlined clearable dense required rows="3" counter auto-grow />
                                      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y dense min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="costAdd.date"
                                            label="Gerçekleşme Tarihi"
                                            :rules="rules.exist"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="costAdd.date" @input="menu2 = false" :max="maxDate"></v-date-picker>
                                      </v-menu>
                                      <v-text-field v-model="costAdd.price" label="Tutar" value="0" :rules="rules.exist" prefix="₺" type="number" dense></v-text-field>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="green darken-1" @click="addCostToList" :disabled="!formValid.costAdd" :loading="loading">Ekle</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                              <v-col>
                                <v-expansion-panels>
                                  <v-expansion-panel v-for="(cost, index) in payload.costs" :key="index">
                                    <v-expansion-panel-header>
                                      <template v-slot:default="{ open }">
                                        <v-row no-gutters>
                                          <v-col cols="8"> {{ costByCode(cost.cost).text }} </v-col>
                                          <v-col cols="2" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                              <span v-if="!open"> {{ cost.date | dayjs('DD MMMM YYYY') }} </span>
                                            </v-fade-transition>
                                          </v-col>
                                          <v-col cols="2" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                              <span v-if="!open"> {{ $n(cost.price, 'currency', 'tr-TR') }} </span>
                                            </v-fade-transition>
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <div class="text-body-2 ma-2">
                                        <v-icon left>mdi-text</v-icon>
                                        <span>{{ cost.explanation }}</span>
                                      </div>
                                      <v-chip-group>
                                        <v-chip><v-icon left>mdi-barcode</v-icon>{{ cost.cost }}</v-chip>
                                        <v-chip><v-icon left>mdi-calendar</v-icon>{{ cost.date | dayjs('DD MMMM YYYY') }}</v-chip>
                                        <v-chip><v-icon left>mdi-tag</v-icon>{{ $n(cost.price, 'currency', 'tr-TR') }}</v-chip>
                                        <v-chip v-if="cost.includeBill"><v-icon left>mdi-receipt</v-icon>Fatura'da Göster</v-chip>
                                      </v-chip-group>
                                      <v-divider></v-divider>
                                      <div class="d-flex justify-end">
                                        <v-btn text color="red darken-1" @click="payload.costs.splice(index, 1)">Sil</v-btn>
                                      </div>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-btn block text @click="step = 4" :loading="loading">Önceki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clean">İptal</v-btn>
                <v-btn color="primary" @click.stop="addRecord" :loading="loading" :disabled="!formValid.vehicle || !formValid.explanation || !formValid.snitch">Kaydet</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600">
            <v-card>
              <v-card-title class="headline"> Bu kayıtı silmek istediğinizden emin misiniz? </v-card-title>
              <v-card-text>
                <v-chip-group>
                  <v-chip><v-icon left>mdi-car</v-icon>{{ edited.plate }}</v-chip>
                  <v-chip><v-icon left>mdi-calendar</v-icon>{{ edited.date | dayjs('DD MMMM YYYY') }}</v-chip>
                  <v-chip><v-icon left>mdi-account-alert</v-icon>{{ edited.snitch }}</v-chip>
                </v-chip-group>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="clean" :loading="loading"> İptal </v-btn>
                <v-btn color="green darken-1" text @click.stop="deleteRecordConfirm" :loading="loading"> Onaylıyorum </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEdit" fullscreen transition="dialog-bottom-transition" scrollable>
            <v-card>
              <v-toolbar dark color="primary" max-height="70">
                <v-btn icon dark @click="clean" :loading="loading">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Güncelle: {{ edited.plate }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn dark text @click.stop="addRecord" :loading="loading" :disabled="!formValid.vehicle || !formValid.explanation || !formValid.snitch">Kaydet</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-container fluid>
                  <v-stepper v-model="step">
                    <v-stepper-header>
                      <v-stepper-step :editable="true" :complete="step > 1" step="1">Araç Bilgisi</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :editable="true" :complete="step > 2" step="2">Yakalama Bilgisi</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :editable="true" :complete="step > 3" step="3">Açıklama</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :editable="true" :complete="step > 4" step="4">Dosyalar</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :editable="true" step="5">Maliyetler</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-form v-model="formValid.vehicle" ref="vehicle">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-text-field v-model="edited.plate" :rules="rules.plate" label="Plaka" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-text-field v-model="edited.chassis" label="Şase" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-select v-model="edited.brand" :items="brands" :rules="rules.exist" label="Marka" outlined item-value="_id" item-text="brand" required />
                              </v-col>
                              <v-col>
                                <v-select v-model="edited.bodyType" :items="vehicleTypeI18n" :rules="rules.exist" label="Araç tipi" outlined required />
                              </v-col>
                              <v-col>
                                <v-text-field v-model="edited.series" :rules="rules.exist" label="Model" outlined clearable required />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block color="primary" @click="step = 2" :disabled="!formValid.vehicle" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <v-form v-model="formValid.snitch" ref="snitch">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-text-field v-model="edited.snitch" :rules="rules.exist" label="Bildiren" outlined clearable required />
                              </v-col>
                              <v-col>
                                <v-combobox v-model="edited.owner" :rules="rules.exist" :items="companies" item-text="short" item-value="short" label="Ait Olduğu Kurum" outlined clearable required />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 1" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 3" :disabled="!formValid.snitch" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <v-form v-model="formValid.explanation" ref="explanation">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-textarea v-model="edited.explanation" :rules="rules.exist" label="Giriş Açıklaması" outlined clearable required rows="3" counter auto-grow />
                              </v-col>
                              <v-col>
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="edited.date" label="Giriş Tarihi" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="edited.date" @input="menu = false" :max="maxDate"></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 2" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 4" :disabled="!formValid.explanation" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="4">
                        <v-form v-model="formValid.files" ref="files">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-file-input
                                  v-model="edited.files"
                                  show-size
                                  counter
                                  chips
                                  multiple
                                  label="Yeni Belge"
                                  accept="image/*,application/pdf,text/plain,.doc,.docx,.odt,.xls,.xlsx"
                                  prepend-icon="mdi-file"
                                ></v-file-input>
                              </v-col>
                              <v-col>
                                <v-file-input v-model="edited.pictures" show-size counter chips multiple label="Yeni Fotoğraf" accept="image/*" prepend-icon="mdi-camera"></v-file-input>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-sheet class="mx-auto" elevation="2">
                                  <v-slide-group multiple show-arrows>
                                    <v-slide-item v-for="(file, index) in edited.existFiles" :key="index">
                                      <v-card class="ma-1">
                                        <v-card-subtitle>{{ file.name }}</v-card-subtitle>
                                        <v-card-text class="text-center">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon v-bind="attrs" v-on="on" color="blue" large>mdi-file-pdf</v-icon>
                                            </template>
                                            <span>{{ file.name }}</span>
                                          </v-tooltip>
                                        </v-card-text>
                                        <v-card-actions>
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on" :href="file.path" target="_blank"><v-icon>mdi-binoculars</v-icon></v-btn>
                                            </template>
                                            <span>Görüntüle</span>
                                          </v-tooltip>
                                          <v-spacer />
                                          <v-fade-transition>
                                            <v-tooltip top v-if="!file.marked">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon color="red darken-1" v-bind="attrs" v-on="on" @click="markItem('existFiles', index, true)"> <v-icon>mdi-delete</v-icon></v-btn>
                                              </template>
                                              <span>Silmek için işaretle</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon color="green darken-1" v-bind="attrs" v-on="on" @click="markItem('existFiles', index, false)">
                                                  <v-icon>mdi-delete-restore</v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Silme işaretini kaldır</span>
                                            </v-tooltip>
                                          </v-fade-transition>
                                        </v-card-actions>
                                      </v-card>
                                    </v-slide-item>
                                  </v-slide-group>
                                </v-sheet>
                              </v-col>
                              <v-col>
                                <v-sheet class="mx-auto" elevation="2">
                                  <v-slide-group multiple show-arrows>
                                    <v-slide-item v-for="(file, index) in edited.existPictures" :key="index">
                                      <v-card class="ma-1">
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-img :src="file.path" v-bind="attrs" v-on="on" max-height="100" max-width="100"></v-img>
                                          </template>
                                          <span>{{ file.name }}</span>
                                        </v-tooltip>
                                        <v-card-actions>
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn icon v-bind="attrs" v-on="on" :href="file.path" target="_blank"><v-icon>mdi-binoculars</v-icon></v-btn>
                                            </template>
                                            <span>Görüntüle</span>
                                          </v-tooltip>
                                          <v-spacer />
                                          <v-fade-transition>
                                            <v-tooltip top v-if="!file.marked">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon color="red darken-1" v-bind="attrs" v-on="on" @click="markItem('existPictures', index, true)"> <v-icon>mdi-delete</v-icon></v-btn>
                                              </template>
                                              <span>Silmek için işaretle</span>
                                            </v-tooltip>
                                            <v-tooltip top v-else>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon color="green darken-1" v-bind="attrs" v-on="on" @click="markItem('existPictures', index, false)">
                                                  <v-icon>mdi-delete-restore</v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Silme işaretini kaldır</span>
                                            </v-tooltip>
                                          </v-fade-transition>
                                        </v-card-actions>
                                      </v-card>
                                    </v-slide-item>
                                  </v-slide-group>
                                </v-sheet>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block text @click="step = 3" :loading="loading">Önceki</v-btn>
                              </v-col>
                              <v-col>
                                <v-btn block color="primary" @click="step = 5" :disabled="!formValid.files" :loading="loading">Sonraki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                      <v-stepper-content step="5">
                        <v-form v-model="formValid.costs" ref="costs">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-card elevation="2">
                                  <v-card-title>Maliyet Ekle</v-card-title>
                                  <v-card-text>
                                    <v-form v-model="formValid.costAdd">
                                      <v-select v-model="costAdd.cost" :items="costDefinitions" :rules="rules.exist" label="Maliyet" outlined clearable required dense />
                                      <v-switch v-model="costAdd.includeBill" label="Faturada Göster" outlined dense></v-switch>
                                      <v-textarea v-model="costAdd.explanation" label="Açıklama" :rules="rules.exist" outlined clearable dense required rows="3" counter auto-grow />
                                      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y dense min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-model="costAdd.date"
                                            label="Gerçekleşme Tarihi"
                                            :rules="rules.exist"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="costAdd.date" @input="menu2 = false" :max="maxDate"></v-date-picker>
                                      </v-menu>
                                      <v-text-field v-model="costAdd.price" label="Tutar" value="0" :rules="rules.exist" prefix="₺" type="number" dense></v-text-field>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="green darken-1" @click="addCostToList(true)" :disabled="!formValid.costAdd" :loading="loading">Ekle</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                              <v-col>
                                <v-expansion-panels>
                                  <v-expansion-panel v-for="(cost, index) in edited.costs" :key="index">
                                    <v-expansion-panel-header>
                                      <template v-slot:default="{ open }">
                                        <v-row no-gutters>
                                          <v-col cols="8"> {{ costByCode(cost.cost).text }} </v-col>
                                          <v-col cols="2" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                              <span v-if="!open"> {{ cost.date | dayjs('DD MMMM YYYY') }} </span>
                                            </v-fade-transition>
                                          </v-col>
                                          <v-col cols="2" class="text--secondary">
                                            <v-fade-transition leave-absolute>
                                              <span v-if="!open"> {{ $n(cost.price, 'currency', 'tr-TR') }} </span>
                                            </v-fade-transition>
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <div class="text-body-2 ma-2">
                                        <v-icon left>mdi-text</v-icon>
                                        <span>{{ cost.explanation }}</span>
                                      </div>
                                      <v-chip-group>
                                        <v-chip><v-icon left>mdi-barcode</v-icon>{{ cost.cost }}</v-chip>
                                        <v-chip><v-icon left>mdi-calendar</v-icon>{{ cost.date | dayjs('DD MMMM YYYY') }}</v-chip>
                                        <v-chip><v-icon left>mdi-tag</v-icon>{{ $n(cost.price, 'currency', 'tr-TR') }}</v-chip>
                                        <v-chip v-if="cost.includeBill"><v-icon left>mdi-receipt</v-icon>Fatura'da Göster</v-chip>
                                      </v-chip-group>
                                      <v-divider></v-divider>
                                      <div class="d-flex justify-end">
                                        <v-btn text color="red darken-1" @click="edited.costs.splice(index, 1)">Sil</v-btn>
                                      </div>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-btn block text @click="step = 4" :loading="loading">Önceki</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clean" :loading="loading">İptal</v-btn>
                <v-btn color="primary" @click.stop="updateRecord" :loading="loading" :disabled="!formValid.vehicle || !formValid.explanation || !formValid.snitch">Kaydet</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:loading>
        <div class="mt-5">
          <v-skeleton-loader type="table-row-divider@5, table-row" :loading="loading"></v-skeleton-loader>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editRecord(item)"> mdi-pencil</v-icon>
        <v-icon small @click.stop="deleteRecord(item)"> mdi-delete</v-icon>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dayjs('DD MMMM YYYY') }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import Vue from 'vue';

const exist = (v) => !!v || 'Bu alan zorunludur';

const costAdd = () => ({
  cost: null,
  includeBill: false,
  explanation: null,
  date: dayjs().format('YYYY-MM-DD'),
  price: 0,
});
const payload = () => ({
  plate: null,
  chassis: null,
  brand: null,
  series: null,
  bodyType: 'automobile',
  date: dayjs().format('YYYY-MM-DD'),
  owner: null,
  explanation: null,
  files: null,
  pictures: null,
  costs: [],
});

export default {
  name: 'Catch',
  data: () => ({
    table: {
      headers: [
        { text: 'Plaka', align: 'start', sortable: true, value: 'plate' },
        { text: 'Marka', align: 'start', sortable: false, value: 'brand' },
        { text: 'Model', align: 'start', sortable: false, value: 'series' },
        { text: 'Tarih', align: 'start', sortable: true, value: 'date' },
        { text: 'Ait Olduğu Kurum', align: 'start', sortable: true, value: 'owner' },
        { text: 'Bildiren', align: 'start', sortable: true, value: 'snitch' },
        { text: 'İşlemler', value: 'actions', sortable: false },
      ],
      catches: [],
      itemsPerPage: 10,
    },
    step: 1,
    maxDate: dayjs().format('YYYY-MM-DD'),
    dialogAdd: false,
    dialogDelete: false,
    dialogEdit: false,
    menu: false,
    menu2: false,
    formValid: {
      vehicle: false,
      snitch: false,
      explanation: false,
      files: false,
      costs: false,
      costAdd: false,
    },
    rules: {
      plate: [exist, (v) => /^\d{2}[A-Z]{1,3}\d{2,5}$/i.test(v) || 'Plaka formatı geçersiz'],
      exist: [exist],
    },
    payload: payload(),
    edited: {},
    costAdd: costAdd(),
    loading: true,
  }),
  created() {
    this.CLEAR();
    Promise.allSettled([this.getDefinitions(), this.getCostDefinitions(), this.getCompanies(), this.fetchCatches()]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapState(['brands', 'vehicleType', 'companies', 'costs']),
    ...mapGetters(['costDefinitions']),
    ...mapGetters('catches', ['catches', 'catchById']),
    vehicleTypeI18n() {
      return this.vehicleType.map((i) => ({ value: i, text: this.$t(i) }));
    },
    costDefinitions() {
      const costs = [];
      for (const [h, l] of Object.entries(this.costs)) {
        costs.push({ header: this.$t(h) });
        costs.push(...l.map((i) => ({ text: `${i.code.toString().toUpperCase()} - ${i.name}`, value: i.code })));
      }
      return costs;
    },
  },
  methods: {
    ...mapActions(['getDefinitions', 'getCostDefinitions', 'getCompanies']),
    ...mapActions('catches', ['fetchCatches', 'setCatch', 'fetchCatch', 'updateCatch', 'removeCatch']),
    ...mapMutations('catches', ['CLEAR']),
    clean() {
      this.loading = false;
      this.dialogAdd = false;
      this.dialogDelete = false;
      this.dialogEdit = false;

      this.$refs.costs.reset();
      this.$refs.vehicle.reset();
      this.$refs.snitch.reset();
      this.$refs.explanation.reset();
      this.$refs.files.reset();

      Vue.set(this, 'costAdd', costAdd());
      Vue.set(this, 'payload', payload());
      Vue.set(this, 'edited', {});
      Vue.set(this, 'step', 1);
    },
    costByCode(code) {
      return this.costDefinitions.find((s) => s.value === code) || {};
    },
    addCostToList(edit) {
      if (edit) {
        this.edited.costs.push({ ...this.costAdd });
      } else {
        this.payload.costs.push({ ...this.costAdd });
      }
      this.costAdd = costAdd();
    },
    async addRecord() {
      this.loading = true;
      await this.setCatch(this.payload);
      this.clean();
    },
    async editRecord(item) {
      this.loading = true;
      await this.fetchCatch(item._id);
      const catch1 = { ...this.catchById(item._id) };
      const edited = {
        ...catch1,
        date: dayjs(catch1.date).format('YYYY-MM-DD'),
        costs: catch1.costs.map((x) => ({ ...x, date: dayjs(x.date).format('YYYY-MM-DD') })),
        pictures: [],
        existPictures: catch1.pictures.map((o) => ({ ...o, marked: false })),
        files: [],
        existFiles: catch1.files.map((o) => ({ ...o, marked: false })),
      };
      Vue.set(this, 'edited', edited);
      this.dialogEdit = true;
      this.loading = false;
    },
    async updateRecord() {
      this.loading = true;
      await this.updateCatch(this.edited);
      this.clean();
    },
    deleteRecord(item) {
      Vue.set(this, 'edited', { ...item });
      this.dialogDelete = true;
    },
    deleteRecordConfirm() {
      this.loading = true;
      this.removeCatch(this.edited._id);
      this.clean();
    },
    markItem(path, index, mark) {
      this.edited[path] = this.edited[path].map((o, i) => {
        return i === index ? { ...o, marked: mark } : { ...o };
      });
    },
  },
};
</script>
